import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SectionTitle from "../components/common/SectionTitle"
import PageImageHeader from "../components/common/PageImageHeader"
import SectionHeaderImage from "../images/schedulePageHeader.jpeg"
import styled from "styled-components"
import Schedule from "../components/Schedule"

const StyledBody = styled.div`
  max-width: 1400px;
  margin: 0 auto;

  .calendar {
    max-width: 1400px;
    margin: 0 auto;

    iframe {
      width: 100%;
      height: 600px;
    }
  }
`

const SchedulePage = () => {
  return (
    <Layout>
      <PageImageHeader imageHeader={SectionHeaderImage} />
      <SectionTitle title="Show Schedule" />
      <StyledBody>
        <Schedule />
      </StyledBody>
    </Layout>
  )
}

export default SchedulePage
